<template>
  <div
    class="sidebar-detached sidebar-left"
    :class="{ 'is-loading': fLoading }"
  >
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              {{ $t('catalog.list.labels.filter') }}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Categories -->
          <div
            v-if="filterOptions.categories"
            class="product-categories"
          >
            <h6
              v-b-toggle.filter-ctg
              class="filter-title mt-0"
            >
              <b-row>
                <b-col cols="10">
                  {{ $t('catalog.list.labels.categories') }}
                </b-col>
                <b-col cols="2">
                  <i class="fa fa-chevron-up" />
                  <i class="fa fa-chevron-down" />
                </b-col>
              </b-row>
            </h6>
            <b-collapse
              id="filter-ctg"
              visible
            >
              <v-select
                v-model="filterOptionsSelected.categories"
                :multiple="true"
                :clearable="true"
                :options="filterOptions.categories"
                :reduce="val => val.value"
              />
            </b-collapse>
          </div>
          <hr>

          <!-- stickers -->
          <div class="product-categories">
            <h6
              v-b-toggle.filter-stk
              class="filter-title"
            >
              <b-row>
                <b-col cols="10">
                  {{ $t('catalog.list.labels.stickers') }}
                </b-col>
                <b-col cols="2">
                  <i class="fa fa-chevron-up" />
                  <i class="fa fa-chevron-down" />
                </b-col>
              </b-row>
            </h6>
            <b-collapse
              id="filter-stk"
              visible
            >
              <div class="item-stickers">
                <div
                  v-for="item in filterOptions.stickers"
                  :key="item.id"
                >
                  <input
                    :id="`f_s_${item.id}`"
                    v-model="filterOptionsSelected.stickers"
                    type="checkbox"
                    :value="item.id"
                  >
                  <label
                    v-b-tooltip.hover.v-primary
                    :for="`f_s_${item.id}`"
                    class="item-stickers-item"
                    :title="$options.filters.transl(item, 'title')"
                  >
                    <b-img
                      fluid
                      :src="$options.filters.mediaUrl(item, 'avatar', 'base')"
                    />
                  </label>
                </div>
              </div>
            </b-collapse>
          </div>
          <hr>

          <!-- languages -->
          <div
            v-if="1 === 2"
            class="product-categories"
          >
            <h6
              v-b-toggle.filter-lng
              class="filter-title"
            >
              <b-row>
                <b-col cols="10">
                  {{ $t('catalog.item.labels.language') }}
                </b-col>
                <b-col cols="2">
                  <i class="fa fa-chevron-up" />
                  <i class="fa fa-chevron-down" />
                </b-col>
              </b-row>
            </h6>
            <b-collapse id="filter-lng">
              <div class="item-stickers lang">
                <div
                  v-for="item in filterOptions.languages"
                  :key="item"
                >
                  <input
                    :id="`f_l_${item}`"
                    v-model="filterOptionsSelected.languages"
                    type="checkbox"
                    :value="item"
                  >
                  <label
                    v-b-tooltip.hover.v-primary
                    :for="`f_l_${item}`"
                    class="item-stickers-item"
                    :title="$t(`general.languages.${item}`)"
                  >
                    <b-img
                      fluid
                      :src="`/language/${item}.svg`"
                    />
                  </label>
                </div>
              </div>
            </b-collapse>
          </div>

          <!-- formats -->
          <div class="product-categories">
            <h6
              v-b-toggle.filter-frm
              class="filter-title"
            >
              <b-row>
                <b-col cols="10">
                  {{ $t('catalog.price.types.title') }}
                </b-col>
                <b-col cols="2">
                  <i class="fa fa-chevron-up" />
                  <i class="fa fa-chevron-down" />
                </b-col>
              </b-row>
            </h6>
            <b-collapse id="filter-frm">
              <div class="item-buttons">
                <div
                  v-for="item in filterOptions.formats"
                  :key="item"
                >
                  <input
                    :id="`f_f_${item}`"
                    v-model="filterOptionsSelected.formats"
                    type="checkbox"
                    :value="item"
                  >
                  <label
                    v-b-tooltip.hover.v-primary
                    :for="`f_f_${item}`"
                    type="button"
                    class="btn btn-outline-primary p-50"
                    :title="$t(`catalog.price.descriptions.${item}`)"
                  >
                    {{ $t(`catalog.price.types.${item}`) }}
                  </label>
                </div>
              </div>
            </b-collapse>
          </div>
          <hr>

          <!-- diapazon -->
          <div class="product-categories">
            <h4
              v-b-toggle.filter-dpz
              class="filter-title"
            >
              <b-row>
                <b-col cols="10">
                  Діапазони
                </b-col>
                <b-col cols="2">
                  <i class="fa fa-chevron-up" />
                  <i class="fa fa-chevron-down" />
                </b-col>
              </b-row>
            </h4>
            <b-collapse
              id="filter-dpz"
              visible
            >
              <div class="item-ranges">
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="6">
                        Підписників
                      </b-col>
                      <b-col cols="6">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ $options.filters.fFormat(filterOptionsSelected.subscribers[0]) }} - {{ $options.filters.fFormat(filterOptionsSelected.subscribers[1]) }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_subscribers_range`"
                    connect
                    :handles="[filterOptionsSelected.subscribers[0], filterOptionsSelected.subscribers[1]]"
                    :range="{ 'min': filterOptions.subscribers[0], 'max': filterOptions.subscribers[1] }"
                    :step="1000"
                    class="mb-1"
                    @end="sliderEnd($event, 'subscribers')"
                    @slide="sliderSlide($event, 'subscribers')"
                  />
                  <b-row>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.subscribers[0]"
                        type="number"
                        :min="filterOptions.subscribers[0]"
                        :max="filterOptions.subscribers[1]"
                        step="1000"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.subscribers[1]"
                        type="number"
                        :min="filterOptions.subscribers[0]"
                        :max="filterOptions.subscribers[1]"
                        step="1000"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="6">
                        Переглядів
                      </b-col>
                      <b-col cols="6">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ $options.filters.fFormat(filterOptionsSelected.views[0]) }} - {{ $options.filters.fFormat(filterOptionsSelected.views[1]) }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_views_range`"
                    connect
                    :handles="[filterOptionsSelected.views[0], filterOptionsSelected.views[1]]"
                    :range="{ 'min': filterOptions.views[0], 'max': filterOptions.views[1] }"
                    :step="1000"
                    class="mb-1"
                    @end="sliderEnd($event, 'views')"
                    @slide="sliderSlide($event, 'views')"
                  />
                  <b-row>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.views[0]"
                        type="number"
                        :min="filterOptions.views[0]"
                        :max="filterOptions.views[1]"
                        step="1000"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.views[1]"
                        type="number"
                        :min="filterOptions.views[0]"
                        :max="filterOptions.views[1]"
                        step="1000"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="6">
                        ER, %
                      </b-col>
                      <b-col cols="6">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ filterOptionsSelected.er[0] }} - {{ filterOptionsSelected.er[1] }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_er_range`"
                    connect
                    :handles="[filterOptionsSelected.er[0], filterOptionsSelected.er[1]]"
                    :range="{ 'min': filterOptions.er[0], 'max': filterOptions.er[1] }"
                    :step="1"
                    class="mb-1"
                    @end="sliderEnd($event, 'er')"
                    @slide="sliderSlide($event, 'er')"
                  />
                  <b-row>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.er[0]"
                        type="number"
                        :min="filterOptions.er[0]"
                        :max="filterOptions.er[1]"
                        step="1000"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.er[1]"
                        type="number"
                        :min="filterOptions.er[0]"
                        :max="filterOptions.er[1]"
                        step="1000"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="6">
                        Ціна
                      </b-col>
                      <b-col cols="6">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ filterOptionsSelected.price[0] }} - {{ filterOptionsSelected.price[1] }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_price_range`"
                    connect
                    :handles="[filterOptionsSelected.price[0], filterOptionsSelected.price[1]]"
                    :range="{ 'min': filterOptions.price[0], 'max': filterOptions.price[1] }"
                    :step="100"
                    class="mb-1"
                    @end="sliderEnd($event, 'price')"
                    @slide="sliderSlide($event, 'price')"
                  />
                  <b-row>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.price[0]"
                        type="number"
                        :min="filterOptions.price[0]"
                        :max="filterOptions.price[1]"
                        step="1000"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.price[1]"
                        type="number"
                        :min="filterOptions.price[0]"
                        :max="filterOptions.price[1]"
                        step="1000"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="6">
                        CPM
                      </b-col>
                      <b-col cols="6">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ $options.filters.fFormat(filterOptionsSelected.cpm[0]) }} - {{ $options.filters.fFormat(filterOptionsSelected.cpm[1]) }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_cpm_range`"
                    connect
                    :handles="[filterOptionsSelected.cpm[0], filterOptionsSelected.cpm[1]]"
                    :range="{ 'min': filterOptions.cpm[0], 'max': filterOptions.cpm[1] }"
                    :step="50"
                    class="mb-1"
                    @end="sliderEnd($event, 'cpm')"
                    @slide="sliderSlide($event, 'cpm')"
                  />
                  <b-row>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.cpm[0]"
                        type="number"
                        :min="filterOptions.cpm[0]"
                        :max="filterOptions.cpm[1]"
                        step="1000"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="filterOptionsSelected.cpm[1]"
                        type="number"
                        :min="filterOptions.cpm[0]"
                        :max="filterOptions.cpm[1]"
                        step="1000"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="range-item">
                  <h6 class="mt-2 mb-1">
                    <b-row>
                      <b-col cols="7">
                        Стать (Ч/Ж)%
                      </b-col>
                      <b-col cols="5">
                        <b-badge
                          class="float-right"
                          variant="secondary"
                        >
                          {{ $options.filters.fFormat(filterOptionsSelected.sex[0]) }} - {{ $options.filters.fFormat(filterOptionsSelected.sex[1]) }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </h6>
                  <veeno
                    :id="`fi_sex_range`"
                    connect
                    :handles="[filterOptionsSelected.sex[0], filterOptionsSelected.sex[1]]"
                    :range="{ 'min': filterOptions.sex[0], 'max': filterOptions.sex[1] }"
                    :step="1"
                    class="mb-1"
                    @end="sliderEnd($event, 'sex')"
                    @slide="sliderSlide($event, 'sex')"
                  />
                </div>
              </div>
            </b-collapse>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, VBTooltip, VBToggle, BCollapse, BBadge, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import veeno from 'veeno'
import _ from 'lodash'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BCollapse,
    BBadge,
    BFormInput,
    vSelect,
    veeno,
  },
  props: {
    filterOptionsSelected: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    fLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    sliderSlide(e, el) {
      this.filterOptionsSelected[el] = _.map(e.values, _.parseInt)
    },
    sliderEnd(e, el) {
      this.filterOptionsSelected[el] = _.map(e.values, _.parseInt)
    },
  },
}
</script>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/12.0.0/nouislider.min.css"; /* swiper for price range */
@import '~@core/scss/vue/libs/vue-slider.scss';
.vue-range-slider.slider-component .slider .slider-process {
  background-color: #7367f0;
}
.vue-range-slider.slider-component .slider-tooltip-wrap .slider-tooltip {
  border: 1px solid #7367f0;
  background-color: #7367f0;
}

.noUi-handle:after, .noUi-handle:before {
  display: none;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  left: -8px;
  top: -6px;
  border-radius: 50%;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -8px;
}

.noUi-connect {
  background: #7367f0;
}

.noUi-horizontal {
  height: 6px;
}
</style>

<style lang="scss" scoped>
.ecommerce-application .sidebar-shop .filter-title {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.item-stickers {
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin:0 0;

  .item-stickers-item {
    width: 40px;
    height: 40px;
    background: rgba(238, 238, 238, 0.6);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 25px;
      max-height: 25px;
    }
  }

  input {
    display: none;
  }

  input:checked {

    & + .item-stickers-item {
      background: #7367f0;
    }
  }

  &.lang {

    img {
      border-radius: 50%;
    }
  }
}

.item-buttons {
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin:0 0;

  input {
    display: none;

    & + .btn {
      padding: 0.786rem 1rem;
    }
  }

  input:checked {

    & + .btn {
      border-color: #7367f0 !important;
      background-color: #7367f0 !important;
      color:#fff !important;
    }
  }
}

.sidebar-detached .card {
  max-height: 100vh;
  overflow: auto;

  .card-body {
    flex:none;
  }
}

@media screen and (min-width: 991px) {
  .sidebar-detached .card {
    max-height: calc(100vh - 120px);
  }
}
</style>
