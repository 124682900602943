<template>
  <div
    v-if="totalChannels"
    id="float_cart"
  >
    <b-card>
      <div class="d-flex align-items-center justify-content-between flex-column flex-md-row text-light">

        <div class="d-none d-sm-flex align-items-center justify-content-start">
          <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-2">
            <b-button
              variant="danger"
              @click="clearCart"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </div>
          <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
            <div class="detail-title mr-1">
              {{ $t('cart.page.titles.channels') }}
            </div>
            <div class="detail-amt">
              {{ totalChannels }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
            <div class="detail-title mr-1 detail-total">
              {{ $t('cart.widget.titles.total') }}
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ this.$options.filters.moneyFormat(subTotalAmount) }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
            <div class="detail-title mr-1">
              {{ $t('catalog.item.labels.subscribers') }}
            </div>
            <div class="detail-amt">
              {{ $options.filters.numberFormat(totalSubscribers) }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
            <div class="detail-title mr-1">
              {{ $t('catalog.item.labels.views') }}
            </div>
            <div class="detail-amt">
              ~ {{ $options.filters.fFormat(totalViews) }}
            </div>
          </div>
          <template v-if="totalSale">
            <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
              <div class="detail-title mr-1 detail-total">
                {{ $t('cart.widget.titles.sale') }}
              </div>
              <div class="detail-amt font-weight-bolder text-error">
                - {{ this.$options.filters.moneyFormat(totalSale) }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-start  flex-column flex-lg-row mr-3">
              <div class="detail-title mr-1 detail-total">
                {{ $t('cart.widget.titles.saletotal') }}
              </div>
              <div class="detail-amt font-weight-bolder text-error">
                {{ this.$options.filters.moneyFormat(totalAmount) }}
              </div>
            </div>
          </template>
        </div>
        <b-button
          variant="success"
          class="mt-0 mt-sm-1 mt-md-0"
          :to="{ name: 'checkout-index' }"
        >
          {{ $t('cart.page.btn.order') }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import _ from 'lodash'
import store from '@/store'

export default {
  mixins: [GlobalMixin],
  computed: {
    itemsCart() {
      return this.$store.state.catalog.cartItems
    },
    subTotalAmount() {
      let total = 0
      // this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) * i.quantity })
      this.itemsCart.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) })
      return total ? parseInt(total, 10) : 0
    },
    totalSale() {
      let total = 0
      this.itemsCart.forEach(i => {
        // total += (_.includes(this.projects, i.product.id) && i.product.has_sale === 'enabled' && i.product.sale) ? parseInt(((i.product.sale * (this.calcDiscount(i) || this.calcPrice(i)) * i.quantity) / 100), 10) : 0
        total += (_.includes(this.projects, i.product.id) && i.product.has_sale === 'enabled' && i.product.sale) ? parseInt(((i.product.sale * (this.calcDiscount(i) || this.calcPrice(i))) / 100), 10) : 0
      })
      return total ? parseInt(total, 10) : 0
    },
    totalAmount() {
      return this.subTotalAmount - this.totalSale
    },
    totalChannels() {
      return this.itemsCart.length
    },
    totalSubscribers() {
      let total = 0
      this.itemsCart.forEach(i => { total += this.getHistory(i).subscribers })
      return total
    },
    totalViews() {
      let total = 0
      this.itemsCart.forEach(i => { total += this.getHistory(i).views * i.quantity })
      return total
    },
  },
  methods: {
    transformPrices(prices) {
      return prices.map(i => ({
        text: this.$t(`catalog.price.types.${i.type}`),
        value: i.type,
      }))
    },
    selectedPrice(item) {
      return _.filter(item.product.prices, o => o.type === item.priceType)
    },
    selectedDiscount(item) {
      return _.filter(item.product.discounts, o => o.type === item.priceType)
    },
    calcPrice(item) {
      return this.selectedPrice(item) && this.selectedPrice(item).length ? this.selectedPrice(item)[0].price * item.quantity : 0
    },
    calcDiscount(item) {
      return this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price * item.quantity : 0
    },
    priceFormatted(price) {
      return this.$options.filters.moneyFormat(price)
    },
    getHistory(item) {
      let h = {
        subscribers: 0,
        views: 0,
        er: 0,
        cpv: 0,
      }
      const koef = this.koefOptions[item.priceType]
      if (item.product.historyLast) {
        h = {
          subscribers: item.product.historyLast.subscribers,
          views: item.product.historyLast.views * koef,
          er: ((item.product.historyLast.views * koef) / item.product.historyLast.subscribers) * 100,
          cpv: (this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price : this.selectedPrice(item)[0].price) / (item.product.historyLast.views * koef),
        }
      }
      return h
    },
    clearCart() {
      this.confirm(() => {
        localStorage.setItem('cart', JSON.stringify({}))
        store.commit('catalog/UPDATE_CART_ITEMS', {})
        store.commit('catalog/UPDATE_CART_ITEMS_COUNT', 0)
      }, {
        title: this.$t('notifications.confirm.clearCart'),
        text: null,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#float_cart {
  position: fixed;
  padding: 0 2em;
  right:0;
  bottom:0;
  z-index: 9999;
  width: 100%;

  .card {
    margin:0 0 0 260px;
    background: #787878;
  }
}

@media (max-width: 1200px) {
  #float_cart {
    .card {
      margin:0 0;
    }
  }
}
</style>
